<div class="product-header">
  <div class="product-text">
    <h2>ინფორმაცია პერსონალური მონაცემების დამუშავების შესახებ</h2>

    <p>
      გთხოვთ, ყურადღებით გაეცნოთ აღნიშნულ გვერდზე მოცემულ ინფორმაციას. <br />
      დასტურის შემთხვევაში მონიშნოთ შესაბამისი ველი და განაგრძოთ ოპერაცია.
    </p>
  </div>
</div>
<div class="verify-container">
  <div class="buttons-wrapper">
    <button class="info" (click)="openModal(judicialUsage)">იურიდიული პირი</button>
    <button class="info" (click)="openModal(physicalUsage)">ფიზიკური პირი</button>
  </div>

  <h3>
    გთხოვთ გაეცნოთ პოლიტიკას
  </h3>
  <button class="info" (click)="openModal(dataUsage)">პერსონალური მონაცემების დამუშავება</button>

  <form [formGroup]="fg">
    <div class="row">
      <div class="col-12">
        <div class="sn-container">
          <div class="row">
            <div class="col-12 checkbox-wrapper">
              <input type="checkbox" formControlName="ch1" />
              <p>წავიკითხე და ვეთანხმები - თანხმობის ტექსტს</p>
            </div>
            <div class="col-12 checkbox-wrapper">
              <input type="checkbox" formControlName="ch2" />
              <p>
                წავიკითხე და ვეთანხმები -
                <a [routerLink]="['/about/privacy']" target="_blank">კონფიდენციალურობის პოლიტიკას</a>
              </p>
            </div>
            <div class="col-12"></div>
            <h3 style="text-align: left; margin-bottom: 16px;">გაიარეთ იდენტიფიკაცია</h3>
            <div class="col-12">
              <input
                class="main-input"
                type="text"
                placeholder="პ.ნ. ან საიდენტიფიკაციო"
                formControlName="sn"
                maxlength="11"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div *ngIf="f.sn.touched && f.sn.invalid" class="sn-error">
          <div *ngIf="f.sn.errors.required">შეიუვანეთ პ.ნ. ან საიდენტიფიკაციო</div>
          <div *ngIf="f.sn.errors.minlength">პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
          <div *ngIf="f.sn.errors.maxlength">პ.ნ. / საიდენტიფიკაციო შედგება 9-11 ციფრისგან</div>
          <div *ngIf="f.sn.errors.pattern">პ.ნ. / საიდენტიფიკაციო ნომერი შედგება მხოლოდ ციფრებისგან</div>
        </div>
      </div>
      <div class="col-12">
        <div class="button-verify-container">
          <button class="info" [disabled]="!fg.valid" (click)="verify()">ვეთანხმები</button>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="submitted">
    <div class="row">
      <div class="col-12">
        <div class="iban-number">
          GE26TB7913136020100017
        </div>
      </div>
      <div class="col-12">
        <p class="iban-banner">
          <br />
          საკრედიტო ისტორიის გადასამოწმებლად საჭიროა 0.01 ლარის ან სხვა სიმბოლური თანხის გადარიცხვა მითითებულ ანგარიშის
          ნომერზე. ლიზინგის სისტემა მოახდენს მომხმარებლის მიერ განაცხადში და საგადახდო დავალებაში მითითებული პირადი
          ინფორმაციის შედარებას და მონაცემების თანხვედრის შემთხვევაში, ინფორმაცია დასამუშავებლად კრედიტ ინფოში
          გაიგზავნება.
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #dataUsage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">პერსონალური მონაცემების დამუშავება</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="verify-text">
      მე, (შემდგომში „კლიენტი“) წინამდებარე დოკუმენტზე თანხმობის გამოხატვით ვადასტურებ, რომ სს „თიბისი ლიზინგი“
      (შემდგომში - სალიზინგო კომპანია) ჩემი განცხადების განხილვისა და/ან მომსახურების გაწევის მიზნით, უფლებამოსილია
      მიიღოს და დაამუშავოს ჩემი პერსონალური მონაცემები, შემდგომში საქმიანი ურთიერთობის დამყარებისა და სალიზინგო
      მომსახურების გაწევის მიზნით. ამასთანავე ჩემთვის ცნობილია, რომ მონაცემებს, მათ შორის პერსონალური მონაცემებს
      სალიზინგო კომპანია ინახავს კანონმდებლობით დადგენილი ვადით, ხოლო ჩემს მიერ მოწოდებული ყველა პერსონალური მონაცემი
      არის უტყუარი და ზუსტი.
      <br /><br />
      გაცნობიერებული მაქვს საქართველოს კანომდებლობით გათვალისწინებული უფლებები, რომ უფლება მაქვს მოვითხოვო ჩემი
      მონაცემების დამუშავების შესახებ ინფორმაცია, ასევე მონაცემთა გაცნობა და ასლის მიღება, ისევე როგორც მონაცემების
      გასწორება, განახლება, შევსება, დამუშავების შეწყვეტა, წაშლა, განადგურება, გადატანა (თუ ეს ტექნიკურად შესაძლებელია),
      დაბლოკვა, გასაჩივრება ან/და თანხმობის უკან გამოხმობა, შესატყვისი მოთხოვნის გამოგზავნით ელექტრონული ფოსტის
      მეშვეობით მისამართზე <a href="mailto: info@tbcleasing.ge">info@tbcleasing.ge</a>. მოთხოვნის მიღების შემდეგ სს
      „თიბისი ლიზინგი“ შესაბამის ვადებში (3-იდან 10 სამუშაო დღემდე) მოახდენს რეაგირებას განცხადებაზე და საჭიროების
      შემთვევაში დააკმაყოფილებს მოთხოვნას, ხოლო იმ შემთვევაში, თუ სალიზინგო კომპანია ჩათვლის მოთხვნას არალეგიტიმურად მე
      (მონაცემთა სუბიექტს) გამომეგზავნება დასაბუთებული უარი განცხადებაში მითითებულ ელ. ფოსტაზე. <br /><br />
      თანხობის უკან გამოხმობის შემთხვევაში, ჩემი მოთხოვნის შესაბამისად, მონაცემთა დამუშავება შეწყდება ან/და დამუშავებული
      მონაცემები წაიშალება ან/და განადგურდება მოთხოვნიდან არაუგვიანეს 10 სამუშაო დღისა, თუ მონაცემთა დამუშავების სხვა
      საფუძველი არ არსებობს.
      <br /><br />
      <i>
        * წინამდებარე დოკუმენტზე აქტიური მოქმედებით თანხმობის გამოხატვისას, თქვენ ასევე ეთანხმებით ქვემოთ მოცემულ
        ვებ-გვერდზე განთავსებულ კონფიდენციალურობის პოლიტიკას, რა მიზნითაც გთხოვთ გასხნათ აღნიშნული დოკუმენტები გაეცნოთ
        მათ და მხოლოდ მათი გაცნობის შემდეგ მონიშნოთ კონკრეტულ ღილაკზე დაკლიკებით ე.წ. Check Box-(ებ)ის საშუალებით -
        „წავიკითხე და ვეთანხნები“. წინააღმდეგ შემთხვევაში, თქვენ ვერ შეძლებთ სს „თიბისი ლიზინგის“ მომსახურებით
        სარგებლობას და თქვენს მიერ შევსებული ინფომაცია ავტომატურად წაიშლება.
      </i>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">დახურვა</button>
  </div>
</ng-template>

<ng-template #physicalUsage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">ფიზიკური პირი</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="verify-text">
      <b>თანხმობა მონაცემების დამუშავების თაობაზე</b>
      <br />
      <br />
      მე, (სახელი, გვარი) გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ205016560), საქართველოს
      კანონმდებლობით დადგენილი წესით და პირობებით, დაამუშაოს წინამდებარე განაცხადში, კრედიტორთან დაცული, ასევე, ჩემ
      შესახებ საკრედიტო ბიუროებში დაცული და მათ მიერ მოწოდებული ინფორმაცია ჩემი, როგორც მომავალი ლიზინგის
      მიმღების/თავდების/უზრუნველყოფის მიმწოდებლის გადახდისუნარიანობის ანალიზის მიზნით.
      <br />
      <br />
      გაცნობიერებული მაქვს საქართველოს კანონმდებლობით გათვალისწინებული უფლებები, რომ მონაცემთა სუბიექტის/მომხმარებლის
      მოთხოვნის შემთხვევაში, მონაცემთა დამმუშავებელი ვალდებულია გაასწოროს, განაახლოს, დაამატოს, დაბლოკოს, წაშალოს ან
      გაანადგუროს მონაცემები, თუ ისინი არასრულია, არაზუსტია, არ არის განახლებული, ან თუ მათი შეგროვება და დამუშავება
      განხორციელდა კანონის საწინააღმდეგოდ.
      <br />
      <br />
      აღნიშნული თანხმობა მოქმედია ხელმოწერიდან/დადასტურებიდან 30 სამუშაო დღის განმავლობაში.
      <br />
      <br />
      <b>მომხმარებლის თანხმობა მონაცემების საკრედიტო საინფორმაციო ბიუროსთვის მიწოდების შესახებ</b>
      <br />
      <br />
      გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ 205016560, მის: ქ. თბილისი, ი. ჭავჭავაძის გამზ.
      #76 „მ“), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით დაამუშაოს ჩემს შესახებ საკრედიტო/არასაკრედიტო და
      სხვა რელევანტური ინფორმაცია, რომელიც მიეწოდება საკრედიტო საინფორმაციო ბიუროს. ხსენებული ინფორმაცია მუშავდება
      მომხმარებლის გადახდისუნარიანობის ანალიზის მიზნისთვის და იგი კანონმდებლობით დადგენილი წესით ხელმისაწვდომი იქნება
      საკრედიტო საინფორმაციო ბიუროში ჩართული სესხის გამცემი ორგანიზაციებისთვის და ინფორმაციის მიმღები/მიმწოდებელი
      პირებისათვის.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">დახურვა</button>
  </div>
</ng-template>

<ng-template #judicialUsage let-modal>
  <div class="modal-header">
    <h4 class="modal-title">იურიდიული პირი</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="verify-text">
      <b>თანხმობა მონაცემების დამუშავების თაობაზე</b>
      <br />
      <br />
      მე, (სახელი, გვარი) (კომპანიის დასახელება და ს/ნ ----------) წარმომადგენელი გამოვხატავ თანხმობას და უფლებას ვაძლევ
      სს „თიბისი ლიზინგს“ (ს/ნ 205016560), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით, დაამუშაოს
      წინამდებარე განაცხადში, კრედიტორთან დაცული, ასევე, ჩემ შესახებ საკრედიტო ბიუროებში დაცული და მათ მიერ მოწოდებული
      ინფორმაცია ჩემი, როგორც მომავალი ლიზინგის მიმღების/თავდების/უზრუნველყოფის მიმწოდებლის გადახდისუნარიანობის ანალიზის
      მიზნით.
      <br />
      <br />
      გაცნობიერებული მაქვს საქართველოს კანონმდებლობით გათვალისწინებული უფლებები, რომ მონაცემთა სუბიექტის/მომხმარებლის
      მოთხოვნის შემთხვევაში, მონაცემთა დამმუშავებელი ვალდებულია გაასწოროს, განაახლოს, დაამატოს, დაბლოკოს, წაშალოს ან
      გაანადგუროს მონაცემები, თუ ისინი არასრულია, არაზუსტია, არ არის განახლებული, ან თუ მათი შეგროვება და დამუშავება
      განხორციელდა კანონის საწინააღმდეგოდ.
      <br />
      <br />
      აღნიშნული თანხმობა მოქმედია ხელმოწერიდან/დადასტურებიდან 60 სამუშაო დღის განმავლობაში.
      <br />
      <br />
      <b>მომხმარებლის თანხმობა მონაცემების საკრედიტო საინფორმაციო ბიუროსთვის მიწოდების შესახებ</b>
      <br />
      <br />
      გამოვხატავ თანხმობას და უფლებას ვაძლევ სს „თიბისი ლიზინგს“ (ს/ნ205016560, მის: ქ. თბილისი, ი. ჭავჭავაძის გამზ. #76
      „მ’), საქართველოს კანონმდებლობით დადგენილი წესით და პირობებით დაამუშაოს ჩემს შესახებ საკრედიტო/არასაკრედიტო და
      სხვა რელევანტური ინფორმაცია, რომელიც მიეწოდება საკრედიტო საინფორმაციო ბიუროს. ხსენებული ინფორმაცია მუშავდება
      მომხმარებლის გადახდისუნარიანობის ანალიზის მიზნისთვის და იგი კანონმდებლობით დადგენილი წესით ხელმისაწვდომი იქნება
      საკრედიტო საინფორმაციო ბიუროში ჩართული სესხის გამცემი ორგანიზაციებისთვის და ინფორმაციის მიმღები/მიმწოდებელი
      პირებისათვის.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">დახურვა</button>
  </div>
</ng-template>

export const tbclOffers: Offer[] = [
  {
    imageUrl: 'sinotruk_howo_tx.jpg',
    title: '10 000 ლარამდე ფასდაკლება SINOTRUK HOWO TX-ზე',
    subheader: 'AutoTechno',
    documentUrl: 'sinotruk_howo_tx.pdf'
  },
  {
    imageUrl: 'kia.jpg',
    title: 'სპეციალური შეთავაზება Kia Ceed-ის ავტომობილზე',
    subheader: 'Kia Georgia',
    documentUrl: 'kia.pdf'
  },
  {
    imageUrl: 'volvo_georgia.jpg',
    title: '1 წლიანი 0%-იანი ლიზინგი',
    subheader: 'Volvo Car Sakartvelo',
    documentUrl: 'volvo_georgia.pdf'
  },
  {
    imageUrl: 'porsche.jpg',
    title: '0%-იანი ლიზინგი და 10%-იანი თანამონაწილეობა',
    subheader: 'Porsche Centre Tbilisi',
    documentUrl: 'porsche.pdf'
  },
  {
    imageUrl: 'mazda.jpg',
    title: 'Mazda-ს ახალი მოდელები 1 წლიანი 0%-იანი ლიზინგით',
    subheader: 'Mazda Center Tegeta',
    documentUrl: 'mazda.pdf'
  },
  {
    imageUrl: 'hyundai.jpg',
    title: 'შეთავაზება Hyundai-ს ავტომობილებზე',
    subheader: 'Hyundai Georgia',
    documentUrl: 'hyundai.pdf'
  },
  {
    imageUrl: 'bestune.jpg',
    title: 'სპეციალური შეთავაზება BESTUNE-ზე',
    subheader: 'Autogallery',
    documentUrl: 'bestune.pdf'
  },
  {
    imageUrl: 'scooters.jpg',
    title: 'შეიძინე ელექტრო სკუტერები ფასდაკლებით + საჩუქარი',
    subheader: 'Autogallery',
    documentUrl: 'scooters.pdf'
  },
  {
    imageUrl: 'range_rover.jpg',
    title: 'სპეციალური ფასდაკლება Range Rover-ზე',
    subheader: 'GT Motors',
    documentUrl: 'range_rover.pdf'
  },
  {
    imageUrl: 'jaguar.jpg',
    title: 'სპეციალური ფასდაკლება Jaguar-ზე',
    subheader: 'GT Motors',
    documentUrl: 'jaguar.pdf'
  },
  {
    imageUrl: 'mini2.jpg',
    title: 'შეიძინე MINI-ს სასურველი მოდელი 35%-მდე ფასდაკლებით',
    subheader: 'GT Motors',
    documentUrl: 'mini2.pdf'
  },
  {
    imageUrl: 'bmw2.jpg',
    title: 'შეიძინე BMW-ს სასურველი მოდელი ფასდაკლებით',
    subheader: 'GT Motors',
    documentUrl: 'bmw2.pdf'
  },
  {
    imageUrl: 'changan.jpg',
    title: 'შეიძინე Changan Eado EV 460 ლიზინგით სპეციალურ ფასად',
    subheader: 'Changan Georgia',
    documentUrl: 'changan.pdf'
  },
  {
    imageUrl: 'volvo.jpg',
    title: 'შეიძინე Volvo EC40 1 წლიანი 0%-იანი ლიზინგით',
    subheader: 'Volvo Car Sakartvelo',
    documentUrl: 'volvo.pdf'
  },
  {
    imageUrl: 'toyota_corolla.jpg',
    title: 'შეიძინე Toyota Corolla Cross შემცირებული თანამონაწილეობით და სასურველი საჩუქარით',
    subheader: 'Toyota Center Tbilisi',
    documentUrl: 'toyota_corolla.pdf'
  },
  {
    imageUrl: 'auto_gallery.jpg',
    title: 'ისარგებლე 0%-იანი ლიზინგით 6 თვის მანძილზე',
    subheader: 'Auto Gallery',
    documentUrl: 'auto_gallery.pdf'
  },
  {
    imageUrl: 'citroen.jpg',
    title: 'შეიძინე Citroën-ის ავტომობილი ლიზინგით და მიიღე სასურველი საჩუქარი',
    subheader: 'Citroën',
    documentUrl: 'citroen.pdf'
  },
  {
    imageUrl: 'defender.jpg',
    title: 'ისარგებლე 30%-მდე ფასდაკლებით და დამატებითი საჩუქრებით',
    subheader: 'GT Motors',
    documentUrl: 'defender.pdf'
  },
  {
    imageUrl: 'mitsubishi.jpg',
    title: '14 000 ლარამდე ფასდაკლება 2023 წლის MITSUBISHI-ზე',
    subheader: 'Caucasus Motors',
    documentUrl: 'mitsubishi.pdf'
  },
  {
    imageUrl: 'mini.jpg',
    title: '35%-მდე ფასდაკლება MINI-ს ავტომობილებზე',
    subheader: 'GT Motors',
    documentUrl: 'mini.pdf'
  },
  {
    imageUrl: 'toyota_c-hr.jpg',
    title: 'ახალი C-HR სპეციალურ ფასად',
    subheader: 'Toyota Center Tegeta',
    documentUrl: 'toyota_c-hr.pdf'
  },
  {
    imageUrl: 'bmw.jpg',
    title: '75,000 ლარამდე ფასდაკლება BMW-ს ავტომობილებზე',
    subheader: 'GT Motors',
    documentUrl: 'bmw.pdf'
  },
  {
    imageUrl: 'byd_seagull.jpg',
    title: 'შეიძინე BYD Seagull 44,795 ლარად',
    subheader: 'Solar Auto',
    documentUrl: 'byd_seagull.pdf'
  }
];

export interface Offer {
  imageUrl: string;
  title: string;
  subheader: string;
  documentUrl: string;
}
